<template>
  <div class="container h-100 bg-first p-1">
    <b-card
      class="mt-1"
      no-body
    >
      <div class="pl-1 pt-1">
        <h3 class="m-0">
          ประวัติสลากฯ ของฉัน
        </h3>
        <!-- {{ lottoset }}
        <small>
          รายการสลากทั้งหมด
          <span class="text-danger">{{ lottoset.length }} รายการ</span>
        </small> -->
      </div>

      <div style="overflow-x: auto">
        <table class="table">
          <colgroup>
            <col width="35%">
            <col width="35%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>เล่มสลาก</th>
              <th>สถานะผลรางวัล</th>
              <th>งวดประจำวันที่</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in showdata"
              :key="item._id"
            >
              <td>
                <div class="card6">
                  <div class="chip">
                    <span class="bankk">{{ item.lottonumber }}</span>
                  </div>
                  <div class="number">
                    {{ item.roundth }}
                  </div>
                  <div class="name">
                    <div class="price-lotto1">
                      <h1>{{ item.price }} บาท</h1>
                    </div>
                  </div>
                  <div class="from">
                    <span class="txt-a">งวดที่</span> 1
                  </div>
                  <div class="to">
                    <span class="txt-a">ชุดที่</span> {{ item.series_no }}
                  </div>
                </div>
              </td>
              <td>
                <span
                  v-if="item.status === 2"
                  class="badge badge-light-warning"
                >{{ CheckStatus(item).text }}</span>
                <span
                  v-if="item.status === 4"
                  class="badge badge-light-success"
                >{{ CheckStatus(item).text }}</span>
                <span
                  v-if="item.status === 5"
                  class="badge badge-light-danger"
                >{{ CheckStatus(item).text }}</span>
              </td>
              <td>
                <!-- <h6 class="mb-0 font-weight-bolder">
                  {{ item.count }} ใบ
                </h6> -->
                <span>งวด {{ item.roundth }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="currentPage"
          class="mt-1"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="totalRows"
          first-number
          last-number
          align="center"
          @input="getSet()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BPagination } from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    BCard,
    BPagination,
  },
  data() {
    return {
      number: 1,
      cart: [],
      // lottoset: [],
      showdata: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
    }
  },
  mounted() {
    this.getSet()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    CheckStatus(data) {
      if (data.status === 2) {
        return { color: 'warning', text: 'รอผลรางวัล' }
      } if (data.status === 4) {
        if (data.result_win_type === 'num1') {
          const show = 'เลขท้าย 1 ตัว'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        }
        if (data.result_win_type === 'num2') {
          const show = 'เลขท้าย 2 ตัว'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num3_front') {
          const show = '3 ตัวหน้า'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num3t_front') {
          const show = '3 ตัวโต๊ด'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num3t_back') {
          const show = '3 ตัวโต๊ด'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num3_back') {
          const show = '3 ตัวหลัง'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num4') {
          const show = '4 ตัวท้าย'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num5') {
          const show = '5 ตัวท้าย'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        } if (data.result_win_type === 'num6') {
          const show = 'ที่ 1'
          return { color: 'success', text: `ถูกรางวัล ${show} จำนวน ${data.reward} บาท` }
        }
      } if (data.status === 5) {
        return { color: 'danger', text: 'ไม่ถูกรางวัล' }
      }
    },
    detail(item) {
      this.$router.push({ name: 'lottery-set-agent', params: { id: item } })
    },
    async getSet() {
      const obj = {
        page: this.currentPage,
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/getsold/member', obj)
        .then(ress => {
          this.totalRows = ress.data.total
          this.showdata = ress.data.lottery
        })
    },
    // getSetCount(id) {
    //   this.$http
    //     .get(`https://uatapi.sabaideelotto.com/api/lottery/getlotterybysetcount/${id}`)
    //     .then(ress => {
    //       console.log(ress.data)
    //       return ress.data
    //     })
    // },
    minus() {
      if (this.number > 1) {
        // eslint-disable-next-line no-plusplus
        this.number--
      }
    },
  },

  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ ทั้งหมดของคุณ',
    }
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
  border-collapse: separate;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  scrollbar-width: none;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.table tbody tr {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.table tbody tr td {
  background: #fff;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.card6 {
                        background: url(/img/lotto-2.jpg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        /* margin: auto; */
                        position: relative;
                        height: 159px;
                        max-width: 317px;
                        min-width: 317px;
                        backdrop-filter: blur(30px);
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        overflow: hidden;
                    }

                    .logo img {
                        top: 15px;
                        right: 10px;
                        width: 80px;
                        height: auto;
                        opacity: 0.8;
                    }

                    .chip img {
                        top: 60px;
                        left: 20px;
                        width: 50px;
                        height: auto;
                        opacity: 0.8;
                    }

                    .bankk {
                        top: 13px;
                        right: 48px;
                        font-size: 1.2rem;
                        font-weight: 800;
                        letter-spacing: 8px;
                        color: rgb(0, 0, 0);
                    }

                    .txt-a {
                        top: 80px;
                        right: 46px;
                        font-size: 0.7rem;
                        font-weight: 500;
                        padding: 1px 3px;
                        border-radius: 5px;
        color: rgb(0, 0, 0);
            background-color: #01B295;
            margin-bottom: 0;
        /* สีของตัวหนังสือ และ พื้นหลัง*/
        }

        .number {
            right: 52px;
            font-size: 0.8rem;
            font-weight: 600;
            top: 70px;
            color: rgb(0, 107, 89);
        }

        .name {
            /* font-size: 1rem; */
            left: 5px;
            bottom: -7px;
        }

        .from {
            font-size: 0.8rem;
            bottom: 12px;
            color: #000000;
            font-weight: 700;
            right: 120px;
        }

        .to {
            font-size: 0.8rem;
            bottom: 12px;
            color: #000000;
            font-weight: 700;
            right: 50px;
        }
</style>
